import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  Select,
  AutoComplete,
  Switch,
  DatePicker,
} from "antd";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import CustomFieldsView from "../custom-fields/custom-fields";
import { SvgIcon, Row, Col, RecommendedSize, ImageLoader } from "../../common";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./basic-information.scss";
import AddRelatedProductsCodeModal from "./add-related-products-code/add-related-products-code";
import ProductInfoMenu from "../product-info-menu/product-info-menu";
import ProductDetailsCard from "../product-details-card/product-details-card";
import { serverApis } from "../../../constants/server-apis";
import { connect } from "react-redux";
import {
  createLoadingSelector,
  resetStoreState,
  getProductDetails,
  lookupOptions,
  getSearchCategories,
  saveAndPublishProductDetails,
} from "../../../redux/action";
import {
  getObjectDeepCopy,
  isObjectEmpty,
  isObjectNotEmpty,
  isValueAnInteger,
} from "../../../common/utils";
import confirm from "antd/lib/modal/confirm";
import ItemPricingView from "../item-pricing/item-pricing";
import ManageVariantsView from "../manage-variants/manage-variants";
import { showAlert } from "../../../common/alert-messages";
import TextArea from "antd/lib/input/TextArea";
import ImprintOptionsView from "../imprint-options/imprint-options";
import Checkbox from "antd/lib/checkbox/Checkbox";
// import StylingImageModal from "./styling-image-modal/";
import { baseServerAdress } from "../../../constants/server-address";
import { flagValueForBool, isFlagTrueY } from "../../../common/methods";
import ReactPlayer from "react-player";

const ADD_PARENT_ID = "addparentid";

const { Option } = Select;

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ script: "sub" }, { script: "super" }],
      [{ color: [] }],
      ["link"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ["clean"],
    ],
  },
};

class ProductBasicInformationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeClass: "normal",
      productDetailsRequestObj: {},
      screenFields: {},
      selectedRelatedItems: [],
      relatedProducts: [],
      addSubCategoryId: "",
      parentField: "",
      childField: "",
      innerField: "",
      searchCategoryObj: null,
      changedImageObjects: [],
      selectedMenuKey: 0,
      resetEditor: false,
    };
  }

  resetCategoryStates = () => {
    this.setState({
      addSubCategoryId: "",
      parentField: "",
      childField: "",
      innerField: "",
      searchCategoryObj: null,
    });
  };

  handleScroll = () => {
    if (document.getElementById("root").scrollTop > 13) {
      this.setState({ activeClass: "fixTop" });
    } else {
      this.setState({ activeClass: "normal" });
    }
  };

  componentDidMount() {
    if (this.props.match.params.productid) {
      this.props.getProductDetails(this.props.match.params.productid);
    }
    document
      .getElementById("root")
      .addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this.props.resetStoreState(serverApis.getProductDetails.actionName);

    document
      .getElementById("root")
      .removeEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate(prevProps) {
    if (
      isObjectNotEmpty(this.props.productDetails.product_menu_details) &&
      isObjectEmpty(this.state.screenFields)
    ) {
      this.setState({
        screenFields: this.props.productDetails.product_menu_details[0]
          .sub_menu[0],
      });
    }

    if (
      isObjectNotEmpty(this.props.productDetails) &&
      prevProps.productDetails.id !== this.props.productDetails.id
    ) {
      let productDetailsRequestObj = this.getInitialisedProductDetailRequestObject();

      this.setState({
        productDetailsRequestObj: productDetailsRequestObj,
      });
    }

    if (isObjectNotEmpty(this.props.saveAndPublishProductDetailsResponse)) {
      showAlert.success(this.props.saveAndPublishProductDetailsResponse);
      this.props.resetStoreState(
        serverApis.saveAndPublishProductDetails.actionName
      );
      this.props.resetStoreState(serverApis.getProductDetails.actionName);
      this.setState({
        screenFields: {},
        productDetailsRequestObj: {},
        selectedMenuKey: 0,
      });

      this.resetCategoryStates();

      if (this.props.match.params.productid) {
        this.props.getProductDetails(this.props.match.params.productid);
      }
    }
    if (this.state.resetEditor) {
      this.setState({ resetEditor: false });
    }
  }
  handleInputFieldChange = (event) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    let value = event.target.value; //field value
    let name = event.target.getAttribute("name"); //field name
    productDetailsRequestObj[name] = value;
    this.setState({ productDetailsRequestObj });
  };

  onChangeEditor = (string, delta, source, editor, api_key) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;

    let newHtml = editor.getHTML();

    if (
      isObjectEmpty(editor.getText()) ||
      editor.getText().trim().length === 0
    ) {
      newHtml = "";
    }

    if (productDetailsRequestObj[api_key] !== newHtml) {
      productDetailsRequestObj[api_key] = newHtml;
      this.setState({ productDetailsRequestObj });
    }
  };

  handleSaveAndPublish = () => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    if (isObjectNotEmpty(this.state.searchCategoryObj)) {
      let categories = [];

      this.state.searchCategoryObj.forEach((parent) => {
        if (isObjectEmpty(parent.child)) {
          categories.push({ id: parent.id });
        } else {
          parent.child.forEach((child) => {
            if (isObjectEmpty(child.child)) {
              categories.push({ id: child.id });
            } else {
              child.child.forEach((inner) => {
                categories.push({ id: inner.id });
              });
            }
          });
        }
      });

      productDetailsRequestObj["categories"] = categories;
    }
    this.props.saveAndPublishProductDetails(productDetailsRequestObj);
  };
  handleDiscardChanges = () => {
    let productDetailsRequestObj = this.getInitialisedProductDetailRequestObject();

    this.setState({
      productDetailsRequestObj: productDetailsRequestObj,
      resetEditor: true,
    });
    this.resetCategoryStates();
  };

  getHtmlFieldDescription = (htmlField) => {
    let productDescriptionField = {};
    // this.props.productDetails.product_menu_details[0].sub_menu[0].fields.find(
    //   (field) => field.view_type === htmlField.view_type
    // );

    this.props.productDetails.product_menu_details.forEach((menu) => {
      menu.sub_menu.forEach((subMenu) => {
        subMenu.fields.forEach((field) => {
          if (field.view_type === htmlField.view_type) {
            productDescriptionField = field;
          }
        });
      });
    });

    const productDescription = isObjectEmpty(productDescriptionField)
      ? ""
      : productDescriptionField.value;

    return isObjectNotEmpty(
      this.state.productDetailsRequestObj[htmlField.api_key]
    )
      ? this.state.productDetailsRequestObj[htmlField.api_key]
      : productDescription;
  };

  getInitialisedProductDetailRequestObject = () => {
    return {
      id: this.props.productDetails.id,
      catalog_item_id: this.props.productDetails.catalog_item_id,
    };
  };

  onChange = (event) => {
    let selectedRelatedItems = this.state.selectedRelatedItems;
    selectedRelatedItems.push(event.target.value);
    this.setState({
      selectedRelatedItems,
    });
  };
  handleDeleteRelatedProductCodes = (id, key) => {
    confirm({
      title: "Do you really want to Delete ?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        let removedProduct = [];
        let productDetailsRequestObj = this.state.productDetailsRequestObj;
        let relatedProducts = this.state.relatedProducts;

        if (isObjectNotEmpty(productDetailsRequestObj[key])) {
          removedProduct = productDetailsRequestObj[key].filter(
            (product) => product.id === id
          );
          const index = productDetailsRequestObj[key].indexOf(
            removedProduct[0]
          );
          if (index > -1) {
            productDetailsRequestObj[key].splice(index, 1);
          }
        } else {
          removedProduct = relatedProducts.filter(
            (product) => product.id === id
          );
          const index = relatedProducts.indexOf(removedProduct[0]);
          if (index > -1) {
            relatedProducts.splice(index, 1);
          }
        }
        this.setState({
          relatedProducts,
          productDetailsRequestObj,
        });
      },
    });
  };
  onClickLeftMenu = (field, menuIndex, itemIndex) => {
    let key = `${menuIndex}${itemIndex}`;
    this.setState({
      screenFields: field,
      selectedMenuKey: key,
    });
  };
  handleChange = (selectedOptions, api_key) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    let value = selectedOptions; //field value
    let name = api_key; //field name
    productDetailsRequestObj[name] = value;
    this.setState({ productDetailsRequestObj });
  };

  handleSave = (api_key, selectedItemsForAddItem) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    let relatedProducts = this.state.relatedProducts;
    let name = api_key; //field name

    productDetailsRequestObj[name] = this.state.relatedProducts.concat(
      selectedItemsForAddItem
    );

    relatedProducts = relatedProducts.concat(selectedItemsForAddItem);
    productDetailsRequestObj[name] = [
      ...new Map(
        productDetailsRequestObj[name].map((item) => [item["id"], item])
      ).values(),
    ];
    relatedProducts = [
      ...new Map(relatedProducts.map((item) => [item["id"], item])).values(),
    ];

    this.setState({
      productDetailsRequestObj,
      relatedProducts,
    });
  };

  resetIdForAddNew = (id) => {
    this.props.resetStoreState(serverApis.getSearchCategories.actionName);
    this.setState({
      addSubCategoryId: id,
    });
  };
  onSelectedSearchCategoryParent = (array, value, option) => {
    this.setState({
      parentField: value,
    });
    let searchCategoryObj = [];

    if (this.state.searchCategoryObj === null) {
      searchCategoryObj = array;
    } else {
      searchCategoryObj = this.state.searchCategoryObj;
    }
    let alreadyExisting = searchCategoryObj.filter(
      (order) => order.id === option.option.id
    );
    if (isObjectNotEmpty(alreadyExisting)) {
      showAlert.error("Category already exists!");
    } else {
      searchCategoryObj.push(option.option);
    }

    this.setState({
      searchCategoryObj,
      parentField: "",
    });
  };
  onSelectedSearchCategoryChild = (array, value, option) => {
    this.setState({
      childField: value,
    });
    let searchCategoryObj = [];
    if (this.state.searchCategoryObj === null) {
      searchCategoryObj = array;
    } else {
      searchCategoryObj = this.state.searchCategoryObj;
    }

    let parent = searchCategoryObj.filter(
      (order) => order.id === option.option.parent_collection_id
    );

    let alreadyExisting = isObjectNotEmpty(parent[0].child)
      ? parent[0].child.filter((order) => order.id === option.option.id)
      : [];

    if (isObjectNotEmpty(alreadyExisting)) {
      showAlert.error("Sub-Category already exists!");
    } else {
      if (parent[0].child) {
        parent[0].child.push(option.option);
      } else {
        parent[0]["child"] = [option.option];
      }
    }
    this.setState({
      searchCategoryObj,
      childField: "",
    });
  };
  onSelectedSearchCategoryInnerChild = (array, value, option) => {
    this.setState({
      innerField: value,
    });
    let searchCategoryObj = [];
    if (this.state.searchCategoryObj === null) {
      searchCategoryObj = array;
    } else {
      searchCategoryObj = this.state.searchCategoryObj;
    }
    searchCategoryObj.filter((parent) => {
      if (isObjectNotEmpty(parent.child)) {
        let child = parent.child;

        let matchedIdObj = child.filter(
          (order) => order.id === option.option.parent_collection_id
        );
        if (isObjectNotEmpty(matchedIdObj)) {
          if (matchedIdObj[0].child) {
            let alreadyExisting = matchedIdObj[0].child.filter(
              (order) => order.id === option.option.id
            );
            if (isObjectNotEmpty(alreadyExisting)) {
              showAlert.error("Sub-Sub-Category already exists!");
            } else {
              matchedIdObj[0].child.push(option.option);
            }
          } else {
            matchedIdObj[0]["child"] = [option.option];
          }
        }
      }
      return null;
    });

    this.setState({
      searchCategoryObj,
      innerField: "",
    });
  };
  handleDeleteParent = (array, index) => {
    confirm({
      title: "Do you really want to Delete ?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        let searchCategoryObj = [];
        if (this.state.searchCategoryObj === null) {
          searchCategoryObj = array;
        } else {
          searchCategoryObj = this.state.searchCategoryObj;
        }

        searchCategoryObj.splice(index, 1);

        this.setState({
          searchCategoryObj,
        });
      },
    });
  };
  handleDeleteChild = (object, array, index) => {
    confirm({
      title: "Do you really want to Delete ?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        let searchCategoryObj = [];
        if (this.state.searchCategoryObj === null) {
          searchCategoryObj = array;
        } else {
          searchCategoryObj = this.state.searchCategoryObj;
        }

        let parent = searchCategoryObj.filter(
          (order) => order.id === object.parent_collection_id
        );
        parent[0].child.splice(index, 1);

        this.setState({
          searchCategoryObj,
        });
      },
    });
  };
  handleDeleteInner = (object, array, index) => {
    confirm({
      title: "Do you really want to Delete ?",
      // content: "Are you sure you want to sign out from your account?",
      onOk: () => {
        let searchCategoryObj = [];
        if (this.state.searchCategoryObj === null) {
          searchCategoryObj = array;
        } else {
          searchCategoryObj = this.state.searchCategoryObj;
        }
        searchCategoryObj.filter((parent) => {
          if (isObjectNotEmpty(parent.child)) {
            let child = parent.child;

            let matchedIdObj = child.filter(
              (order) => order.id === object.parent_collection_id
            );
            if (isObjectNotEmpty(matchedIdObj)) {
              matchedIdObj[0].child.splice(index, 1);
            }
          }
          return null;
        });
        this.setState({
          searchCategoryObj,
        });
      },
    });
  };
  handleTextFieldChange = (event, key) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    let value = event.target.value; //field value
    let name = key; //field name
    productDetailsRequestObj[name] = value;
    this.setState({ productDetailsRequestObj });
  };
  manageVariantsImageChange = (array, key) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;

    productDetailsRequestObj[key] = array;
    this.setState({
      productDetailsRequestObj,
    });
  };
  // requestObjectForItemPricing = (key, value) => {
  //   let productDetailsRequestObj = this.state.productDetailsRequestObj;
  //   let reqObj = [];
  //   value.map((table) => {
  //     table.price_details.map((field) => {
  //       if (field.is_editable) {
  //         reqObj.push(field);
  //       }
  //       return null;
  //     });
  //     return null;
  //   });

  //   productDetailsRequestObj[key] = reqObj;
  //   this.setState({ productDetailsRequestObj });
  // };
  requestObjForImprintLocation = (key, value) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[key] = value;
    this.setState({ productDetailsRequestObj });
  };
  requestObjForCustomField = (key, values) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    let reqObj = [];
    values.forEach((customField) => {
      reqObj.push({
        id: customField.id,
        title: customField.title,
        value: customField.value,
      });
    });
    productDetailsRequestObj[key] = reqObj;
    this.setState({ productDetailsRequestObj });
  };
  // toggleDiv = (checked) => {
  //   let productDetailsRequestObj = this.state.productDetailsRequestObj;
  //   productDetailsRequestObj["enable_strike_price"] = checked;
  //   this.setState({ productDetailsRequestObj });
  // };
  // changeDateAndTime = (value, dateString) => {
  //   let productDetailsRequestObj = this.state.productDetailsRequestObj;

  //   productDetailsRequestObj["strike_price_expiration"] = dateString;
  //   this.setState({ productDetailsRequestObj });
  // };

  configrationCheckboxBOOLY = (checked, key) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[key] = checked ? "Y" : "N";
    this.setState({ productDetailsRequestObj });
  };

  configrationCheckbox = (checked, key) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[key] = checked.target.checked;
    this.setState({ productDetailsRequestObj });
  };

  onChangeExtendedTier = (key, value) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[key] = value;
    this.setState({ productDetailsRequestObj });
  };

  addLifeStyleImage = (event, field) => {
    let imageArry = this.getLifeStyleImages(field);
    let lifestyleImageObject = { id: "" };
    lifestyleImageObject.file_data = event.target.files[0];

    lifestyleImageObject.image_file = URL.createObjectURL(
      event.target.files[0]
    );

    let updateImageArry = isObjectEmpty(imageArry) ? [] : imageArry;

    updateImageArry = [lifestyleImageObject, ...imageArry];

    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[field.api_key] = updateImageArry;
    this.setState({ productDetailsRequestObj });

    event.target.value = null;
  };

  onSelectChangeFile = (event, field) => {
    let defaultImageObject = { file_name: event.target.files[0].name };
    defaultImageObject.file_data = event.target.files[0];

    defaultImageObject.image_file = URL.createObjectURL(event.target.files[0]);

    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[field.api_key] = defaultImageObject;
    this.setState({ productDetailsRequestObj });

    event.target.value = null;
  };

  removeDefaultImageNew = (field) => {
    let defaultImageObject = { delete_image: true };

    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[field.api_key] = defaultImageObject;

    this.setState({ productDetailsRequestObj });
  };

  removeDefaultImage = (field) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;

    delete productDetailsRequestObj[field.api_key];
    this.setState({ productDetailsRequestObj });
  };

  getLifeStyleImages = (field) => {
    if (this.state.productDetailsRequestObj[field.api_key]) {
      return this.state.productDetailsRequestObj[field.api_key];
    } else {
      return getObjectDeepCopy(field.value);
    }
  };

  removeLifeStyleImage = (index, field) => {
    let imageArry = this.getLifeStyleImages(field);

    if (index > -1) {
      // only splice array when item is found
      imageArry.splice(index, 1); // 2nd parameter means remove one item only
    }

    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[field.api_key] = imageArry;
    this.setState({ productDetailsRequestObj });
  };

  getVideoList = (field) => {
    if (this.state.productDetailsRequestObj[field.api_key]) {
      return this.state.productDetailsRequestObj[field.api_key];
    } else {
      return getObjectDeepCopy(field.value);
    }
  };

  addVideo = (event, field) => {
    let videoArry = this.getVideoList(field);
    let videoObject = { id: "" };
    videoObject.file_data = event.target.files[0];

    videoObject.image_file = URL.createObjectURL(event.target.files[0]);

    let updateVideoArry = isObjectEmpty(videoArry) ? [] : videoArry;

    updateVideoArry = [videoObject, ...videoArry];

    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[field.api_key] = updateVideoArry;
    this.setState({ productDetailsRequestObj });

    event.target.value = null;
  };

  removeVideo = (index, field) => {
    let videoArry = this.getVideoList(field);

    if (index > -1) {
      // only splice array when item is found
      videoArry.splice(index, 1); // 2nd parameter means remove one item only
    }

    let productDetailsRequestObj = this.state.productDetailsRequestObj;
    productDetailsRequestObj[field.api_key] = videoArry;
    this.setState({ productDetailsRequestObj });
  };

  publishChargeOnWebsiteChanged = (checked, field, index) => {
    let productDetailsRequestObj = this.state.productDetailsRequestObj;

    let arraySetupCharges = getObjectDeepCopy(field.value);

    if (isObjectEmpty(productDetailsRequestObj[field.api_key])) {
      arraySetupCharges = getObjectDeepCopy(field.value);
    } else {
      arraySetupCharges = productDetailsRequestObj[field.api_key];
    }

    arraySetupCharges[index].publish_on_web = flagValueForBool(checked);

    productDetailsRequestObj[field.api_key] = arraySetupCharges;
    this.setState({ productDetailsRequestObj });
  };

  publishChargeOnWebsite = (field, index) => {
    if (isObjectNotEmpty(this.state.productDetailsRequestObj[field.api_key])) {
      return isFlagTrueY(
        this.state.productDetailsRequestObj[field.api_key][index].publish_on_web
      );
    } else {
      return isFlagTrueY(field.value[index].publish_on_web);
    }
  };

  fieldsView = () => {
    if (
      isObjectNotEmpty(this.state.screenFields) &&
      isObjectNotEmpty(this.state.screenFields.fields)
    ) {
      return this.state.screenFields.fields.map((field, i) => {
        if (field.view_type === 1) {
          return (
            <Col key={i} xs={6} className="mt-3">
              <Form.Item className="mb-2">
                <label htmlFor="product_name">{field.title}</label>
                <Input
                  id={field.title}
                  className="ant-input-xl"
                  suffix={<SvgIcon name="lock" viewbox="0 0 22.996 30.661" />}
                  disabled
                  value={field.value}
                />
              </Form.Item>
            </Col>
          );
        } else if (field.view_type === 2) {
          return (
            <Col key={i} className="mt-3" xs="6">
              <Form.Item className="mb-2">
                <label htmlFor="image_alt_tag">{field.title} </label>
                <Input
                  className="ant-input-xl"
                  id={field.title}
                  name={field.api_key}
                  value={
                    this.state.productDetailsRequestObj[field.api_key] ||
                    this.state.productDetailsRequestObj[field.api_key] === ""
                      ? this.state.productDetailsRequestObj[field.api_key]
                      : field.value
                  }
                  onChange={this.handleInputFieldChange}
                />
              </Form.Item>
            </Col>
          );
        } else if (field.view_type === 3) {
          return (
            <Col key={i} xs={12} className="mt-3">
              <Form.Item className="mb-2">
                <label htmlFor="description">{field.title}</label>

                {!this.state.resetEditor && (
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    placeholder="Product Description"
                    defaultValue={this.getHtmlFieldDescription(field)}
                    onChange={(string, delta, source, editor) => {
                      this.onChangeEditor(
                        string,
                        delta,
                        source,
                        editor,
                        field.api_key
                      );
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          );
        } else if (field.view_type === 4) {
          if (
            isObjectEmpty(this.state.relatedProducts) &&
            isObjectNotEmpty(field.value)
          ) {
            this.setState({
              relatedProducts: field.value,
            });
          }

          return (
            <>
              <Col key={i} xs={12} className="mt-3">
                <label>{field.title} </label>
                <div className="related-product-code">
                  <ul>
                    {this.state.productDetailsRequestObj[field.api_key]
                      ? this.state.productDetailsRequestObj[field.api_key].map(
                          (product, i) => {
                            return (
                              <li>
                                <div
                                  className="remove-codeicon"
                                  onClick={() => {
                                    this.handleDeleteRelatedProductCodes(
                                      product.id,
                                      field.api_key
                                    );
                                  }}
                                >
                                  <div className="close-icon">
                                    <SvgIcon
                                      name="close"
                                      viewbox="0 0 298.667 298.667"
                                    />
                                  </div>
                                </div>
                                {product.web_code}
                              </li>
                            );
                          }
                        )
                      : this.state.relatedProducts.map((product, i) => {
                          return (
                            <li key={i}>
                              <div
                                className="remove-codeicon"
                                onClick={() => {
                                  this.handleDeleteRelatedProductCodes(
                                    product.id,
                                    field.api_key
                                  );
                                }}
                              >
                                <div className="close-icon">
                                  <SvgIcon
                                    name="close"
                                    viewbox="0 0 298.667 298.667"
                                  />
                                </div>
                              </div>
                              {product.web_code}
                            </li>
                          );
                        })}

                    <AddRelatedProductsCodeModal
                      handleSave={this.handleSave}
                      apiKey={field.api_key}
                    />
                  </ul>
                </div>
              </Col>
            </>
          );
        } else if (field.view_type === 6) {
          return (
            <Col key={i} xs={6} className="showonsite mt-3 mb-2">
              <div className="showonsite-website">
                <SvgIcon name="eye-outline" viewbox="0 0 17.004 10.838" />{" "}
                {field.value === "Y"
                  ? " Visible on Website"
                  : " Hidden on Website"}
              </div>
            </Col>
          );
        } else if (field.view_type === 7) {
          return (
            <Col key={i} xs={6} className="showonsite-update mt-3 mb-2">
              <SvgIcon name="updated" viewbox="0 0 184.443 210.792" />{" "}
              {`  Last updated : ${field.value}`}
            </Col>
          );
        } else if (field.view_type === 5) {
          if (!this.state.emptySearchCategoryObj) {
          }
          const dataSource = this.props.searchCategoriesDetails;
          const options = dataSource.map((opt) => (
            <Option key={opt.id} value={opt.name} option={opt}>
              {opt.name}
            </Option>
          ));
          let arrayToMap =
            this.state.searchCategoryObj === null
              ? field.value
              : this.state.searchCategoryObj;
          return (
            <Col key={i} xs={12} className="mb-2">
              {arrayToMap.map((item, parentIndex) => {
                return (
                  <div key={parentIndex}>
                    <ul className="ctg-tree">
                      {" "}
                      <li>
                        <Form.Item className="mb-2">
                          <AutoComplete
                            className="search-withicon"
                            dropdownClassName="search-feild-drop"
                            dropdownMatchSelectWidth={false}
                            dropdownStyle={{ width: 300 }}
                            size="large"
                            style={{ width: "100%" }}
                            value={item.name}
                            disabled
                          >
                            <Input.Search
                              className="ant-input-xl"
                              placeholder=""
                              enterButton
                            />
                          </AutoComplete>
                          <div
                            className="delete-ctgs"
                            onClick={() => {
                              this.handleDeleteParent(field.value, parentIndex);
                            }}
                          >
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </Form.Item>
                      </li>
                      <li>
                        <ul className="subctg-tree">
                          {isObjectNotEmpty(item.child)
                            ? item.child.map((subitem, childIndex) => {
                                return (
                                  <div key={childIndex}>
                                    <li>
                                      <Form.Item className="mb-2">
                                        <AutoComplete
                                          className="search-withicon"
                                          dropdownClassName="search-feild-drop"
                                          dropdownMatchSelectWidth={false}
                                          dropdownStyle={{ width: 300 }}
                                          size="large"
                                          style={{ width: "100%" }}
                                          disabled
                                          value={subitem.name}
                                        >
                                          <Input.Search
                                            className="ant-input-xl"
                                            placeholder=""
                                            enterButton
                                          />
                                        </AutoComplete>
                                        <div
                                          className="delete-ctgs"
                                          onClick={() => {
                                            this.handleDeleteChild(
                                              subitem,
                                              field.value,
                                              childIndex
                                            );
                                          }}
                                        >
                                          <SvgIcon
                                            name="close"
                                            viewbox="0 0 298.667 298.667"
                                          />
                                        </div>
                                      </Form.Item>
                                    </li>
                                    <li>
                                      <ul className="subsubctg-tree">
                                        {isObjectNotEmpty(subitem.child)
                                          ? subitem.child.map(
                                              (subsubitem, innerIndex) => {
                                                return (
                                                  <li key={innerIndex}>
                                                    <Form.Item className="mb-2">
                                                      <AutoComplete
                                                        className="search-withicon"
                                                        dropdownClassName="search-feild-drop"
                                                        dropdownMatchSelectWidth={
                                                          false
                                                        }
                                                        dropdownStyle={{
                                                          width: 300,
                                                        }}
                                                        size="large"
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        disabled
                                                        value={subsubitem.name}
                                                      >
                                                        <Input.Search
                                                          className="ant-input-xl"
                                                          placeholder=""
                                                          enterButton
                                                        />
                                                      </AutoComplete>
                                                      <div
                                                        className="delete-ctgs"
                                                        onClick={() => {
                                                          this.handleDeleteInner(
                                                            subsubitem,
                                                            field.value,
                                                            innerIndex
                                                          );
                                                        }}
                                                      >
                                                        <SvgIcon
                                                          name="close"
                                                          viewbox="0 0 298.667 298.667"
                                                        />
                                                      </div>
                                                    </Form.Item>
                                                  </li>
                                                );
                                              }
                                            )
                                          : ""}
                                        {this.state.addSubCategoryId ===
                                          subitem.id && (
                                          <AutoComplete
                                            notFoundContent={
                                              this.props
                                                .isFetchingDataSuggestion ? (
                                                <Spin />
                                              ) : (
                                                ""
                                              )
                                            }
                                            className="search-withicon"
                                            dropdownClassName="search-feild-drop"
                                            dropdownMatchSelectWidth={false}
                                            dropdownStyle={{
                                              width: 300,
                                            }}
                                            size="large"
                                            style={{ width: "100%" }}
                                            onClick={() => {
                                              this.props.getSearchCategories(
                                                subitem.id
                                              );
                                            }}
                                            dataSource={options}
                                            onSelect={(value, option) => {
                                              this.onSelectedSearchCategoryInnerChild(
                                                field.value,
                                                value,
                                                option
                                              );
                                            }}
                                            value={this.state.innerField}
                                          >
                                            <Input.Search
                                              className="ant-input-xl"
                                              placeholder=""
                                              enterButton
                                            />
                                          </AutoComplete>
                                        )}
                                        {subitem.child_collection_count > 0 && (
                                          <li>
                                            <Button
                                              className="addfield-btn"
                                              type="link"
                                              icon={
                                                <SvgIcon
                                                  name="plus-circle"
                                                  viewbox="0 0 19 19"
                                                />
                                              }
                                              onClick={() =>
                                                this.resetIdForAddNew(
                                                  subitem.id
                                                )
                                              }
                                            >
                                              ADD SUB-SUB-CATEGORY
                                            </Button>
                                          </li>
                                        )}
                                      </ul>
                                    </li>
                                  </div>
                                );
                              })
                            : ""}
                          {this.state.addSubCategoryId === item.id && (
                            <AutoComplete
                              notFoundContent={
                                this.props.isFetchingDataSuggestion ? (
                                  <Spin />
                                ) : (
                                  ""
                                )
                              }
                              className="search-withicon"
                              dropdownClassName="search-feild-drop"
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 300 }}
                              size="large"
                              style={{ width: "100%" }}
                              onClick={() => {
                                this.props.getSearchCategories(item.id);
                              }}
                              value={this.state.childField}
                              dataSource={options}
                              onSelect={(value, option) => {
                                this.onSelectedSearchCategoryChild(
                                  field.value,
                                  value,
                                  option
                                );
                              }}
                            >
                              <Input.Search
                                className="ant-input-xl"
                                placeholder=""
                                enterButton
                              />
                            </AutoComplete>
                          )}

                          {item.child_collection_count > 0 && (
                            <li>
                              <Button
                                className="addfield-btn"
                                type="link"
                                icon={
                                  <SvgIcon
                                    name="plus-circle"
                                    viewbox="0 0 19 19"
                                  />
                                }
                                onClick={() => this.resetIdForAddNew(item.id)}
                              >
                                ADD SUB-CATEGORY
                              </Button>
                            </li>
                          )}
                        </ul>
                      </li>
                    </ul>
                  </div>
                );
              })}
              {this.state.addSubCategoryId === ADD_PARENT_ID && (
                <AutoComplete
                  notFoundContent={
                    this.props.isFetchingDataSuggestion ? <Spin /> : ""
                  }
                  className="search-withicon"
                  dropdownClassName="search-feild-drop"
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 300 }}
                  size="large"
                  style={{ width: "100%" }}
                  dataSource={options}
                  value={this.state.parentField}
                  onSelect={(value, option) =>
                    this.onSelectedSearchCategoryParent(
                      field.value,
                      value,
                      option
                    )
                  }
                  onClick={() => {
                    this.props.getSearchCategories();
                  }}
                >
                  <Input.Search
                    className="ant-input-xl"
                    placeholder=""
                    enterButton
                  />
                </AutoComplete>
              )}
              <li>
                <Button
                  className="addfield-btn"
                  type="link"
                  icon={<SvgIcon name="plus-circle" viewbox="0 0 19 19" />}
                  onClick={() => this.resetIdForAddNew(ADD_PARENT_ID)}
                >
                  ADD CATEGORY
                </Button>
              </li>
            </Col>
          );
        } else if (field.view_type === 9) {
          return (
            <ManageVariantsView
              key={i}
              fieldValue={field}
              manageVariantsImageChange={this.manageVariantsImageChange}
              productDetailsRequestObj={this.state.productDetailsRequestObj}
            />
          );
        } else if (field.view_type === 14) {
          return (
            <React.Fragment key={i}>
              {field.value.map((value, i) => {
                return (
                  <Col key={i} xs={12} className="mt-3">
                    <Row>
                      <Col className="infosub-heading">{`Accessory ${
                        i + 1
                      } `}</Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mt-3">
                        <Form.Item className="mb-2">
                          <label htmlFor="product_name">Accessory Name</label>
                          <Input
                            id={"value.accessory_item_code"}
                            className="ant-input-xl"
                            suffix={
                              <SvgIcon
                                name="lock"
                                viewbox="0 0 22.996 30.661"
                              />
                            }
                            disabled
                            value={value.accessory_item_code}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={6} className="mt-3">
                        <Form.Item className="mb-2">
                          <label htmlFor="product_name">Item Price</label>
                          <Input
                            id={"value.cost"}
                            className="ant-input-xl"
                            suffix={
                              <SvgIcon
                                name="lock"
                                viewbox="0 0 22.996 30.661"
                              />
                            }
                            disabled
                            value={value.cost}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={6} className="mt-3">
                        <Form.Item className="mb-2">
                          <label htmlFor="product_name">Colors Available</label>
                          <Input
                            id={"value.available_colors"}
                            className="ant-input-xl"
                            suffix={
                              <SvgIcon
                                name="lock"
                                viewbox="0 0 22.996 30.661"
                              />
                            }
                            disabled
                            value={value.available_colors}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </React.Fragment>
          );
        } else if (field.view_type === 11) {
          return (
            <ItemPricingView
              productDetailsRequestObj={this.state.productDetailsRequestObj}
              key={i}
              apiKey={field.api_key}
              requestObjectForItemPricing={this.requestObjectForItemPricing}
              onChangeExtendedTier={this.onChangeExtendedTier}
              fieldValue={field.value}
            />
          );
        } else if (field.view_type === 15) {
          return (
            <Col key={i} xs={12} className="mt-3">
              <label>{field.title} </label>
              <div className="related-product-code">
                <ul>
                  {field.value.map((product, i) => {
                    return <li key={i}>{product.web_code}</li>;
                  })}
                </ul>
              </div>
            </Col>
          );
        } else if (field.view_type === 16) {
          return <ItemPricingView fieldValue={field.value} />;
        } else if (field.view_type === 12) {
          return (
            <Col key={i} xs={6} className="mt-3">
              <Checkbox
                checked={
                  this.state.productDetailsRequestObj.hasOwnProperty(
                    field.api_key
                  )
                    ? this.state.productDetailsRequestObj[field.api_key]
                    : field.value
                }
                onChange={(value) =>
                  this.configrationCheckbox(value, field.api_key)
                }
                className="mr-4 ml-2"
              >
                {field.title}
              </Checkbox>
            </Col>
          );
        } else if (field.view_type === 10) {
          return (
            <Col key={i} xs={12} className="mt-3">
              <Form.Item className="mb-2">
                <label htmlFor="description">{field.title}</label>

                {!this.state.resetEditor && (
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    placeholder="Product Description"
                    defaultValue={this.getHtmlFieldDescription(field)}
                    onChange={(string, delta, source, editor) => {
                      this.onChangeEditor(
                        string,
                        delta,
                        source,
                        editor,
                        field.api_key
                      );
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          );
          // return (
          //   <Col key={i} className="mt-3" xs="12">
          //     <label htmlFor="image_alt_tag">{field.title} </label>
          //     <TextArea
          //       rows="4"
          //       name="about_text"
          //       size="large"
          //       placeholder="Enter"
          //       value={
          //         this.state.productDetailsRequestObj.hasOwnProperty(
          //           field.api_key
          //         )
          //           ? this.state.productDetailsRequestObj[field.api_key]
          //           : field.value
          //       }
          //       onChange={(event) =>
          //         this.handleTextFieldChange(event, field.api_key)
          //       }
          //     />{" "}
          //   </Col>
          // );
        } else if (field.view_type === 18) {
          return (
            <ImprintOptionsView
              key={i}
              apiKey={field.api_key}
              fieldValues={field}
              requestObjForImprintLocation={this.requestObjForImprintLocation}
              productDetailsRequestObj={this.state.productDetailsRequestObj}
            />
          );
        } else if (field.view_type === 19) {
          let showStrikePrice = this.state.productDetailsRequestObj.hasOwnProperty(
            "enable_strike_price"
          )
            ? this.state.productDetailsRequestObj["enable_strike_price"]
            : field.value.enable_strike_price;
          return (
            <Col key={i}>
              <Row className="mt-3">
                <Col xs="2" className="py-3">
                  <div className="infosub-heading mb-1">{field.title}</div>
                  <Switch
                    className="switch-cms"
                    onChange={(checked) => {
                      this.toggleDiv(checked);
                    }}
                    disabled
                    checked={showStrikePrice}
                  />
                </Col>
                <Col xs="10">
                  {showStrikePrice && (
                    <Row>
                      <Col>
                        <Form.Item className="mb-2">
                          <label htmlFor="strike_price_name">
                            Strike Price Name
                          </label>
                          <Input
                            disabled
                            className="ant-input-xl"
                            name={"strike_price_name"}
                            value={
                              this.state.productDetailsRequestObj[
                                "strike_price_name"
                              ]
                                ? this.state.productDetailsRequestObj[
                                    "strike_price_name"
                                  ]
                                : field.value.strike_price_name
                            }
                            onChange={this.handleInputFieldChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item className="mb-2">
                          <label htmlFor="expiration_date">
                            Expiration Date
                          </label>
                          <DatePicker
                            disabled
                            onChange={(value, dateString) => {
                              this.changeDateAndTime(value, dateString);
                            }}
                            showTime={{
                              value: field.value.expiration_date
                                ? moment(
                                    field.value.expiration_date,
                                    "HH:mm:ss"
                                  )
                                : moment(new Date(), "HH:mm:ss"),
                            }}
                            id="expiration_date"
                            className="ant-input-xl"
                            value={
                              this.state.productDetailsRequestObj[
                                "strike_price_expiration"
                              ]
                                ? moment(
                                    this.state.productDetailsRequestObj[
                                      "strike_price_expiration"
                                    ]
                                  )
                                : field.value.expiration_date
                                ? moment(field.value.expiration_date)
                                : moment(new Date(), "HH:mm:ss")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          );
        } else if (field.view_type === 20) {
          return (
            <React.Fragment key={i}>
              {field.value.map((value, i) => {
                return (
                  <Col key={i} xs={12} className="mt-3">
                    <Row>
                      <Col className="infosub-heading">{value.title}</Col>
                      <Col className="text-right">
                        <div className="d-flex align-items-center justify-content-end">
                          <span className="pr-1">View On Website</span>{" "}
                          <Switch
                            checked={this.publishChargeOnWebsite(field, i)}
                            onChange={(checked) =>
                              this.publishChargeOnWebsiteChanged(
                                checked,
                                field,
                                i
                              )
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} className="mt-3">
                        <Form.Item className="mb-2">
                          <label htmlFor="product_name">Imprint Type</label>
                          <Input
                            id={"value.accessory_item_code"}
                            className="ant-input-xl"
                            suffix={
                              <SvgIcon
                                name="lock"
                                viewbox="0 0 22.996 30.661"
                              />
                            }
                            disabled
                            value={value.imprint_type}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={6} className="mt-3">
                        <Form.Item className="mb-2">
                          <label htmlFor="product_name">Run Charge</label>
                          <Input
                            id={"value.cost"}
                            className="ant-input-xl"
                            suffix={
                              <SvgIcon
                                name="lock"
                                viewbox="0 0 22.996 30.661"
                              />
                            }
                            disabled
                            value={value.run_charge}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={6} className="mt-3">
                        <Form.Item className="mb-2">
                          <label htmlFor="product_name">Setup Charge</label>
                          <Input
                            id={"value.available_colors"}
                            className="ant-input-xl"
                            suffix={
                              <SvgIcon
                                name="lock"
                                viewbox="0 0 22.996 30.661"
                              />
                            }
                            disabled
                            value={value.setup_charge}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={6} className="mt-3">
                        <Form.Item className="mb-2">
                          <label htmlFor="product_name">
                            Setup Charge Discount Code
                          </label>
                          <Input
                            id={"value.setup_charge_discount_code"}
                            className="ant-input-xl"
                            suffix={
                              <SvgIcon
                                name="lock"
                                viewbox="0 0 22.996 30.661"
                              />
                            }
                            disabled
                            value={value.setup_charge_discount_code}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </React.Fragment>
          );
        } else if (field.view_type === 21) {
          return (
            <CustomFieldsView
              key={i}
              apiKey={field.api_key}
              fieldValues={field}
              requestObjForCustomField={this.requestObjForCustomField}
              productDetailsRequestObj={this.state.productDetailsRequestObj}
            />
          );
        } else if (field.view_type === 22) {
          return (
            <Col key={i} xs={12} className="mt-3">
              <label className="infosub-heading">{field.title}</label>
              <div className="styling-images-list">
                {/* <StylingImageModal /> */}
                <div className="addstyle-image-btn">
                  <div className="uploadcoverthumbnail-link">
                    <input
                      type="file"
                      onChange={(event) => this.addLifeStyleImage(event, field)}
                    />
                    <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add Image
                  </div>
                </div>
                <ul className="mb-0">
                  {isObjectNotEmpty(this.getLifeStyleImages(field)) &&
                    this.getLifeStyleImages(field).map((image, index) => (
                      <li id={index}>
                        <div
                          className="remove-imgicon"
                          onClick={() => {
                            this.removeLifeStyleImage(index, field);
                          }}
                        >
                          <div className="close-icon">
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                        <div className="images-card">
                          <img
                            src={`${
                              isValueAnInteger(image.id)
                                ? baseServerAdress.IMAGE + "lifestyles/"
                                : ""
                            }${image.image_file}`}
                            alt="Card"
                          />
                        </div>
                      </li>
                    ))}
                </ul>
                <div className="mt-n3">
                  <RecommendedSize width="800" height="800" />
                </div>
              </div>
            </Col>
          );
        } else if (field.view_type === 23) {
          return (
            <Col key={i} xs={6} className="mt-3">
              <label>{field.title}</label>
              <div className="styling-images-list">
                {/* <StylingImageModal /> */}
                <div className="addstyle-image-btn">
                  <div className="uploadcoverthumbnail-link">
                    <input
                      type="file"
                      onChange={(event) =>
                        this.onSelectChangeFile(event, field)
                      }
                    />
                    <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Change
                    Image
                  </div>
                </div>
                <div className="w-100">
                  <ul className="d-inline-block">
                    <li
                      id={"index"}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <div
                        className="remove-imgicon"
                        onClick={() => {
                          this.removeDefaultImageNew(field);
                        }}
                      >
                        <div className="close-icon">
                          <SvgIcon name="close" viewbox="0 0 298.667 298.667" />
                        </div>
                      </div>

                      <div className="images-card">
                        <ImageLoader
                          className="images-card"
                          src={
                            isObjectNotEmpty(
                              this.state.productDetailsRequestObj[field.api_key]
                            ) &&
                            this.state.productDetailsRequestObj[field.api_key]
                              .delete_image
                              ? ""
                              : isObjectNotEmpty(
                                  this.state.productDetailsRequestObj[
                                    field.api_key
                                  ]
                                ) &&
                                this.state.productDetailsRequestObj[
                                  field.api_key
                                ].image_file
                              ? this.state.productDetailsRequestObj[
                                  field.api_key
                                ].image_file
                              : `${baseServerAdress.ITEM_IMAGE}${field.value}`
                          }
                          alt="Card"
                        />
                      </div>
                    </li>
                  </ul>
                  <div className="mt-n4">
                    <RecommendedSize width="800" height="800" />
                  </div>
                </div>
              </div>
            </Col>
          );
        } else if (field.view_type === 24) {
          return (
            <Col key={i} xs={6} className="mt-3">
              <label>{field.title}</label>
              <div className="styling-images-list">
                {/* <StylingImageModal /> */}
                <div className="addstyle-image-btn">
                  <div className="uploadcoverthumbnail-link">
                    <input
                      type="file"
                      onChange={(event) =>
                        this.onSelectChangeFile(event, field)
                      }
                    />
                    <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Change
                    TEMPLATE
                  </div>
                </div>
                <ul>
                  <li
                    id={"index"}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {isObjectNotEmpty(
                      this.state.productDetailsRequestObj[field.api_key]
                    ) && (
                      <div
                        className="remove-imgicon"
                        onClick={() => {
                          this.removeDefaultImage(field);
                        }}
                      >
                        <div className="close-icon">
                          <SvgIcon name="close" viewbox="0 0 298.667 298.667" />
                        </div>
                      </div>
                    )}
                    <div className="">
                      {isObjectNotEmpty(
                        this.state.productDetailsRequestObj[field.api_key]
                      )
                        ? this.state.productDetailsRequestObj[field.api_key]
                            .file_name
                        : field.value}
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          );
        } else if (field.view_type === 25) {
          return (
            <Col key={i} xs={6} className="mt-3">
              <Checkbox
                checked={
                  this.state.productDetailsRequestObj.hasOwnProperty(
                    field.api_key
                  )
                    ? this.state.productDetailsRequestObj[field.api_key]
                    : field.value
                }
                disabled
                className="mr-4 ml-2"
              >
                {field.title}
              </Checkbox>
            </Col>
          );
        } else if (field.view_type === 26) {
          return (
            <Col key={i} xs={12} className="mt-3">
              <label className="infosub-heading">{field.title}</label>
              <div className="styling-images-list">
                {/* <StylingImageModal /> */}
                <div className="addstyle-image-btn">
                  <div className="uploadcoverthumbnail-link">
                    <input
                      type="file"
                      onChange={(event) => this.addLifeStyleImage(event, field)}
                    />
                    <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Add Icon
                  </div>
                </div>
                <ul className="mb-0">
                  {isObjectNotEmpty(this.getLifeStyleImages(field)) &&
                    this.getLifeStyleImages(field).map((image, index) => (
                      <li id={index}>
                        <div
                          className="remove-imgicon"
                          onClick={() => {
                            this.removeLifeStyleImage(index, field);
                          }}
                        >
                          <div className="close-icon">
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                        <div className="images-card">
                          <img
                            src={`${
                              isValueAnInteger(image.id)
                                ? baseServerAdress.IMAGE + "icons/"
                                : ""
                            }${image.image_file}`}
                            alt="Card"
                          />
                        </div>
                      </li>
                    ))}
                </ul>
                <div className="mt-n3">
                  <RecommendedSize width="90" height="90" />
                </div>
              </div>
            </Col>
          );
        } else if (field.view_type === 27) {
          return (
            <Col key={i} xs={12} className="mt-3">
              <label className="infosub-heading">Videos</label>
              <div className="styling-images-list">
                {/* <StylingImageModal /> */}
                <div className="addstyle-image-btn">
                  <div className="uploadcoverthumbnail-link">
                    <input
                      type="file"
                      onChange={(event) => this.addVideo(event, field)}
                    />
                    <SvgIcon name="plus-circle" viewbox="0 0 19 19" /> Videos
                  </div>
                </div>
                <ul className="mb-0">
                  {isObjectNotEmpty(this.getVideoList(field)) &&
                    this.getVideoList(field).map((video, index) => (
                      <li>
                        <div
                          className="remove-imgicon"
                          onClick={() => {
                            this.removeVideo(index, field);
                          }}
                        >
                          <div className="close-icon">
                            <SvgIcon
                              name="close"
                              viewbox="0 0 298.667 298.667"
                            />
                          </div>
                        </div>
                        <div className="images-card">
                          <ReactPlayer
                            url={`${
                              isValueAnInteger(video.id)
                                ? baseServerAdress.ITEM_VIDEO
                                : ""
                            }${video.image_file}`}
                          />
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
          );
        } else if (field.view_type === 28) {
          return (
            <Col key={i}>
              <Row className="mt-3">
                <Col xs="10" className="py-3">
                  <div className="infosub-heading mb-1">{field.title}</div>
                  <Switch
                    className="switch-cms"
                    onChange={(checked) => {
                      this.configrationCheckboxBOOLY(checked, field.api_key);
                    }}
                    checked={
                      this.state.productDetailsRequestObj.hasOwnProperty(
                        field.api_key
                      )
                        ? this.state.productDetailsRequestObj[field.api_key] ===
                          "Y"
                        : field.value === "Y"
                    }
                  />
                </Col>
              </Row>
            </Col>
          );
        } else {
          return null;
        }
      });
    }
  };
  render() {
    return (
      <Spin spinning={this.props.isFetchingData} size="large">
        <React.Fragment>
          <Form className="w-100">
            <div className="right-wrapper">
              <div className="right-wrapper-inner">
                <div className="productinfo-wrapper">
                  <ProductInfoMenu
                    selectedMenuKey={this.state.selectedMenuKey}
                    onClickLeftMenu={this.onClickLeftMenu}
                  />
                  <div className="productinfo-right">
                    <ProductDetailsCard
                      activeClass={this.state.activeClass}
                      productDetails={this.props.productDetails}
                    />

                    <div className="card-box">
                      <div className="card-header">
                        {this.state.screenFields.name}
                      </div>
                      <div className="card-body">
                        <Row>{this.fieldsView()}</Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="productfooter-action">
              <Button
                danger
                className="mr-3"
                onClick={this.handleDiscardChanges}
              >
                Discard Changes
              </Button>
              <Button
                type="primary"
                size="middle"
                onClick={this.handleSaveAndPublish}
              >
                Save & Publish
              </Button>
            </div>
          </Form>
        </React.Fragment>{" "}
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([
  serverApis.getProductDetails.actionName,
  serverApis.saveAndPublishProductDetails.actionName,
]);
const loadingSelectorSuggestion = createLoadingSelector([
  serverApis.getSearchCategories.actionName,
]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  isFetchingDataSuggestion: loadingSelectorSuggestion(state),

  productDetails: state.productDetails,
  lookupOptionsDetails: state.lookupOptionsDetails,
  searchProductsListAutocomplete: state.searchProductsListAutocomplete,
  searchCategoriesDetails: state.searchCategoriesDetails,
  saveAndPublishProductDetailsResponse: state.saveAndPublishProductDetails,
});

export default connect(mapStateToProps, {
  resetStoreState,
  getProductDetails,
  lookupOptions,
  getSearchCategories,
  saveAndPublishProductDetails,
})(ProductBasicInformationView);
